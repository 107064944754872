import request from '@/util/request.js'

// 获取openID
export function getOpenID(params) {
	return request.post(`/index/Parents/getOpenId`, params)
}

// 绑定学生
export function binding(params) {
	return request.post(`/index/Parents/binding`, params)
}

// 获取绑定学生列表
export function students(params) {
	return request.post(`index/Parents/students`, params)
}

// 获取学生服务产品列表
export function productList(params) {
	return request.post(`index/Parents/getGoods`, params)
}

// 获取步骤列表
export function getSteps(params) {
	return request.post(`index/Parents/getSteps`, params)
}

// 获取内容
export function getContent(params) {
	return request.post(`index/Parents/getDetails`, params)
}

// 获取评价及反馈
export function getServerMessage(params) {
	return request.post(`index/Parents/addFeedback`, params)
}
