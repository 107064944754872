import Vue from 'vue'
import VueRouter from 'vue-router'

import StudentList from '../views/studentList.vue'

Vue.use(VueRouter)

const Router = new VueRouter({
	routes: [
		{
			path: '/',
			component: () => import('../views/index.vue'),
			meta: {
				title: ''
			}
		},
		{
			path: '/StudentList',
			component: StudentList,
			meta: {
				title: '学生列表'
			}
		},
		{
			path: '/bindStudent',
			component: () => import('../views/bindStudent.vue'),
			meta: {
				title: '绑定学生'
			}
		},
		{
			path: '/productList',
			component: () => import('../views/productList.vue'),
			meta: {
				title: '产品列表'
			}
		},
		{
			path: '/stepList',
			component: () => import('../views/stepList.vue'),
			meta: {
				title: '产品服务步骤'
			}
		},
		{
			path: '/details',
			component: () => import('../views/details.vue'),
			meta: {
				title: '服务详情'
			}
		},
		{
			path: '/message',
			component: () => import('../views/message.vue'),
			meta: {
				title: '留言与反馈'
			}
		}
	]
})

Router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

export default Router
