import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible'
import router from './router/index'
import 'normalize.css/normalize.css'
import wx from 'weixin-js-sdk'
import { Empty, Toast, Loading, Tag, Rate, Field, Button } from 'vant'
import 'vant/lib/index.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Field)
Vue.use(Rate)
Vue.use(Toast)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(Tag)
Vue.use(Button)
Vue.use(Viewer)

Viewer.setDefaults({
	Options: {
		inline: true, // 启用 inline 模式
		// 'button': true, // 显示右上角关闭按钮
		// 'navbar': true, // 显示缩略图导航
		// 'title': true, // 显示当前图片的标题
		// 'toolbar': true, // 显示工具栏
		tooltip: true, // 显示缩放百分比
		movable: true, // 图片是否可移动
		zoomable: true, // 图片是否可缩放
		rotatable: true, // 图片是否可旋转
		scalable: true, // 图片是否可翻转
		transition: true, // 使用 CSS3 过度
		fullscreen: true, // 播放时是否全屏s
		// 'keyboard': true, // 是否支持键盘
		url: 'data-source' // 设置大图片的 url
	}
})

Vue.config.productionTip = false
Vue.prototype.$wx = wx
new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
